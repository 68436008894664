const black = {
  // lightest
  // lighter
  // light
  main: "#313449" as const,
  // dark
  // darker
  // darkest
};

const gray = {
  lightest: "#F5F7F3" as const,
  main: "#AAA69F" as const,
  dark: "#636566" as const,
};

const gold = {
  lightest: "#fefcf5" as const,
  lighter: "#FFFCF4" as const,
  light: "#FCF0DA" as const,
  main: "#FFBB37" as const,
  // dark
  // darker
  // darkest
};

const green = {
  lightest: "#C8F8E4" as const,
  main: "#0DBE75" as const,
};

const orange = {
  // lightest
  // lighter
  light: "#FFE5CB" as const,
  main: "#FF8811" as const,
  dark: "#E98C00" as const,
  darker: "#DE6F01" as const,
  // darkest
};

const tan = {
  lightest: "#FFFDF9" as const,
  lighter: "#F8F5F1" as const,
  light: "#F9F7F3" as const,
  main: "#9C8E6E" as const,
};

const yellow = {
  lightest: "#FFF8E8" as const,
  main: "#FFD801" as const,
  dark: "#FFE266" as const,
};

export const colors = {
  black,
  gray,
  gold,
  green,
  orange,
  tan,
  text: {
    black: black.main,
    gray: gray.dark,
    tan: tan.main,
  },
  white: "#FFFFFF",
  yellow,
};
